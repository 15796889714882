  h1 {
    text-align: center;
    padding-bottom: 8%;
    font-size: 5vh;
  }
  
li{
    display: flex;
    flex-direction: row;
    align-items: center;
    list-style: none;
}

li div {
    flex: 1;
}

li img {
    max-width: 100%;
}

li p {
    max-width: 100%;
    border: 3px solid;
    border-radius: 10px;
}
  
  .aboutme__wrapper {
    position: relative;
    margin: 50px 0 45px;
  }
  
  .aboutme__items {
    margin-bottom: 24px;
  }
  
  .aboutme__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }
  
  .aboutme__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
  .aboutme__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
  }
  
  .aboutme__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .aboutme__item__img:hover {
    transform: scale(1.1);
  }
  
  .aboutme__item__info {
    padding: 20px 30px 30px;
  }
  
  .aboutme__item__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
  }
  
  .aboutme{
    background-color: rgb(255, 255, 255);
    padding: 20px;
  }

  .aboutme > h1{
    border: 3px solid brown;
    padding: 1%;
    margin: 3%;
    border-radius: 15px;
  }

  .aboutme__container{
    display: flex;
  }

  .aboutPara{
    display: block;
    min-width: 45%;
  }

  .aboutImg{
    width: 50%;
    height: 50%;
    width: -moz-fit-content;
    width: fit-content;
  }

  .aboutImg{
    width: 50%;
    height: 50%;
  }

  .projects-section {
    padding: 3rem 0;
  }
  .projects-section .featured-text {
    padding: 2rem;
  }
  @media (min-width: 992px) {
    .projects-section .featured-text {
      padding: 0 0 0 2rem;
      border-left: 0.5rem solid #64a19d;
    }
  }
  .bg-light {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  }

  .container,
  .container-fluid,
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm {
    width: 100%;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
  }

 
  
  @media (min-width: 576px) {
    .container-sm, .container {
      max-width: 540px;
    }
  }
  @media (min-width: 768px) {
    .container-md, .container-sm, .container {
      max-width: 720px;
    }
  }
  @media (min-width: 992px) {
    .container-lg, .container-md, .container-sm, .container {
      max-width: 960px;
    }
  }
  @media (min-width: 1200px) {
    .container-xl, .container-lg, .container-md, .container-sm, .container {
      max-width: 1140px;
    }
  }
  @media (min-width: 1400px) {
    .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
      max-width: 1320px;
    }
  }

  .px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }
  .gx-0 {
    --bs-gutter-x: 0;
  }
  .mb-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .align-items-center {
    align-items: center !important;
  }

  @media (min-width: 992px) {
  .col-xl-8 {
    display: flex;
    align-items: self-start;
    width: 66.66666667%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-7 {
    display: flex;
    align-items: self-start;
    width: 58.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
}

  .img-fluidAM {
    max-width: 100%;
    display: flex;
    align-items: self-start;
  }
  .mb-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .text-center {
    text-align: center !important;
  }
  .text-black-aboutme {
    --bs-text-opacity: 1; 
    color: rgba(0, 0, 0, 0.75) ;
  }
  
  .mb-0 {
    margin-bottom: 0 !important;
  }

  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .align-items-center {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .aboutme__item {
      margin-bottom: 2rem;
    }
  }