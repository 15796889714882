::placeholder {
  color: rgb(238, 231, 231);
  opacity: .75;
}

.footer-container {
  background-color: #242424;
  padding: 1px 0;
  display: flex;
  flex-direction: column;
}

  /* contact form CSS */
.ContactForm {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  position: relative;
  padding: 10px 0 15px 0;
  width: 70%;
}

.contact-container {
  width: 50%;
}

.contact-row{
  margin: 0 30px;
}

.col {
  width: 50%;
  padding: 0px;
}

.col-6 {
  width: 100%;
}

.formRow {
  padding-bottom: 7px;
  padding-top: 5px;
}

.formInput {
  border: 2px solid white;
}
.col-email{
  padding-right: 2px;
}
.col-phone{
padding-left: 2px;
}

.col-message {
  padding-bottom: 0px;
}

.contact-container h1 {
  font-family: 'Poppins', sans-serif, 'arial';
  font-weight: 600;
  font-size: 30px;
  color: white;
  text-align: center;
  padding-bottom: 5px;
}

.direct-contact-container {
  /* max-width: 400px; */
  width: 50%;
  padding-left: 3rem;
  display: flex;
  justify-content: center;
}

/* Location, Phone, Email Section */
.contact-list {
  list-style-type: none;
  margin-left: -20px;
  padding-right: 20px;
  padding-top:25px;
}

.list-item {
  line-height: 4;
  padding: 1rem;
  color: #aaa;
}

.contact-text {
  font: 300 1rem 'Lato', sans-serif;
  letter-spacing: 1.5px;
  color: #bbb;
}

.place {
  margin-left: 1vh;
}

.phone {
  margin-left: 1vh;
}

.gmail {
  margin-left: 1vh;
}

.contact-text a {
  color: #bbb;
  text-decoration: none;
  transition-duration: 0.2s;
}

.contact-text a:hover {
  color: #fff;
  text-decoration: none;
}

.btn--contact {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 2px solid var(--primary);
  transition: all 0.3s ease-out;
  font-weight: 700;
  font-size: 20px;
  border: 3px solid white;
  width: 250px;
}

.btn--contact:hover {
  transition: all 0.3s ease-out;
  /* background: #fff; */
  background-color: white;
  color: black;
  transition: 250ms;
}

/* ///// inputs /////*/

input:focus ~ label, textarea:focus ~ label, input:valid ~ label, textarea:valid ~ label {
  font-size: 0.75em;
  color: #999;
  top: -5px;
  -webkit-transition: all 0.225s ease;
  transition: all 0.225s ease;
}

@media only screen and (max-width: 768px){
  /* .styled-input {
      width:100%;
  } */
  .ContactForm {
    padding-top: 1rem;
    display: block;
  }
  .contact-container {
    width: 100%;
    padding: 0;
  }
  .direct-contact-container {
    width: 100%;
    padding: 0;
  } 
}

@media screen and (min-width: 768px) and (max-width: 1080px) {
  .btn--contact {
      width:100%;
      float: none;
      text-align:center;
  }
}

@media only screen and (max-width: 395px){
  .btn--contact{
    width: 180px;
    font-size: 1rem;
  }
}

input,
textarea {
  padding: 5px;
  border: 0;
  width: 100%;
  font-size: 1rem;
  background-color: #2d2d2d;
  color: white;
  border-radius: 4px;
}

input:focus,
textarea:focus { outline: 0; }

input:focus ~ span,
textarea:focus ~ span {
  width: 100%;
  -webkit-transition: all 0.075s ease;
  transition: all 0.075s ease;
}

textarea {
  width: 100%;
  min-height: 3em;
}

input[type=checkbox] + label {
color: #ccc;
font-style: italic;
} 

input[type=checkbox]:checked + label {
color: #f00;
font-style: normal;
}

  .footer-logo {
    width: 80%;
  }
  
  .social-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    max-width: 1500px;
    margin: 8px auto 0 auto;
    padding-top: 1px;
  }
  
  .social-logo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  .social-logo img {
    width: 12%;
  }
  
  .website-rights {
    color: #fff;
    margin-bottom: 5px;
  }
