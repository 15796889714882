.projects-section {
    padding: 3rem 0;
  }
  .projects-section .featured-text {
    padding: 2rem;
    font-size: 1.25rem;
    font-weight: 500;
  }
  @media (min-width: 992px) {
    .projects-section .featured-text {
      padding: 0 0 0 2rem;
      border-left: 0.5rem solid #64a19d;
    }
  }
  .bg-light {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  }

  .container,
  .container-fluid,
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm {
    width: 100%;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
  }

  @media (min-width: 576px) {
    .container-sm, .container {
      max-width: 540px;
    }
  }
  @media (min-width: 768px) {
    .container-md, .container-sm, .container {
      max-width: 720px;
    }
  }
  @media (min-width: 992px) {
    .container-lg, .container-md, .container-sm, .container {
      max-width: 960px;
    }
  }
  @media (min-width: 1200px) {
    .container-xl, .container-lg, .container-md, .container-sm, .container {
      max-width: 1140px;
    }
  }
  @media (min-width: 1400px) {
    .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
      max-width: 1320px;
    }
  }

  .px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }
  .gx-0 {
    --bs-gutter-x: 0;
  }
  .mb-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .align-items-center {
    align-items: center !important;
  }

  @media (min-width: 992px) {
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
}

  .img-fluid {
    max-width: 100%;
    height: auto;
    padding-left: 5vh;
  }
  .mb-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .text-center {
    text-align: center !important;
  }
  .text-black-50 {
    --bs-text-opacity: 1;
    color: rgba(0, 0, 0, 0.5);
  }
  .mb-0 {
    margin-bottom: 0 !important;
  }

ul {
    list-style-type: circle;
}

